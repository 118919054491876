import moment from "moment";
import { PRODUCTS_PER_PAGE } from "../config";
import { httpApiGet, httpApiPatch, httpApiPost } from "../helpers/axiosPrivate";

const initialState = {
	loading: false,
	loadingOverlay: false,
	loadingProducts: false,
	error: false,
	message: '',
	products: [],
	hasMoreInternal: false,
	hasMoreExternal: false,
	page: 1,
}

const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
const PRODUCTS_ACTION_FAILURE = 'PRODUCTS_ACTION_FAILURE'
const START_LOADING_PRODUCTS = 'START_LOADING_PRODUCTS'
const STOP_LOADING_PRODUCTS = 'STOP_LOADING_PRODUCTS'
const START_LOADING_PRODUCTS_OVERLAY = 'START_LOADING_PRODUCTS_OVERLAY'
const STOP_LOADING_PRODUCTS_OVERLAY = 'STOP_LOADING_PRODUCTS_OVERLAY'
const SHOW_PRODUCTS_MESSAGE = 'SHOW_PRODUCTS_MESSAGE'
const RESET_PRODUCTS_MESSAGE = 'RESET_PRODUCTS_MESSAGE'
const START_UPDATE_PRODUCT = 'UPDATE_PRODUCT'
const STOP_UPDATE_PRODUCT = 'STOP_UPDATE_PRODUCT'

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_PRODUCTS_SUCCESS:
			return {
				...state,
				...action.payload
			}
		case PRODUCTS_ACTION_FAILURE:
			return {
				...state,
				message: action.message,
				error: true
			}
		case START_LOADING_PRODUCTS:
			return {
				...state,
				loading: true,
			}
		case STOP_LOADING_PRODUCTS:
			return {
				...state,
				loading: false,
			}
		case START_LOADING_PRODUCTS_OVERLAY:
			return {
				...state,
				loadingOverlay: true,
			}
		case STOP_LOADING_PRODUCTS_OVERLAY:
			return {
				...state,
				loadingOverlay: false,
			}
		case SHOW_PRODUCTS_MESSAGE:
			return {
				...state,
				message: action.message,
				error: action.payload
			}
		case RESET_PRODUCTS_MESSAGE:
			return {
				...state,
				message: '',
				error: false
			}
		case START_UPDATE_PRODUCT:
			return {
				...state,
				loadingProducts: true,
			}
		case STOP_UPDATE_PRODUCT:
			return {
				...state,
				loadingProducts: false,
			}
		default:
			return state;
	}
}

export const getProductsAction = (withLoading = true, filters, isInternalProduct, getAllProducts) => async (dispatch, getState) => {

	if (withLoading) {
		dispatch({
			type: START_LOADING_PRODUCTS,
		});
	}
	try {
		let res = null

		if(getAllProducts && !filters.supplierId && !filters.stock){
			res = await Promise.all([
				httpApiGet('products/get-paginated', filters),
				httpApiGet('internal-products/get-paginated', filters),
			]).then((values) => {
				return {
					products: [...values[0].products, ...values[1].products],
					totalExternal: values[0].total,
					totalInternal: values[1].total
				}
			});
		}else if(isInternalProduct && !getAllProducts && !filters.stock){
			res = await httpApiGet('internal-products/get-paginated', filters).then((values) => {
				return {
					products: [...values.products],
					totalExternal: 0,
					totalInternal: values.total
				}
			});
		}else if(!isInternalProduct || getAllProducts){
			res = await httpApiGet('products/get-paginated', filters).then((values) => {
				return {
					products: [...values.products],
					totalExternal: values.total,
					totalInternal: 0
				}
			});
		}

		if (res) {
			dispatch({
				type: GET_PRODUCTS_SUCCESS,
				payload: {
					products: res.products,
					hasMoreInternal: res.totalInternal > PRODUCTS_PER_PAGE,
					hasMoreExternal: res.totalExternal > PRODUCTS_PER_PAGE,
					page: 1
				},
			});
		}
	} catch (error) {
		dispatch({
			type: PRODUCTS_ACTION_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_PRODUCTS,
		});
	}
}

export const getMoreProductsAction = (filters, isInternalProduct, getAllProducts) => async (dispatch, getState) => {
	const products = getState().product.products;
	const page = getState().product.page;

	dispatch({
		type: START_LOADING_PRODUCTS_OVERLAY,
	});

	try {
		let res = null

		if(getAllProducts && !filters.supplierId && !filters.stock){
			res = await Promise.all([
				httpApiGet('products/get-paginated', filters),
				httpApiGet('internal-products/get-paginated', filters),
			]).then((values) => {
				return {
					products: [...values[0].products, ...values[1].products],
					totalExternal: values[0].total,
					totalInternal: values[1].total
				}
			});
		}else if(isInternalProduct && !getAllProducts && !filters.stock){
			res = await httpApiGet('internal-products/get-paginated', filters).then((values) => {
				return {
					products: [...values.products],
					totalExternal: 0,
					totalInternal: values.total
				}
			});
		}else if(!isInternalProduct || getAllProducts){
			res = await httpApiGet('products/get-paginated', filters).then((values) => {
				return {
					products: [...values.products],
					totalExternal: values.total,
					totalInternal: 0
				}
			});
		}

		
		if (res) {
			dispatch({
				type: GET_PRODUCTS_SUCCESS,
				payload: {
					products: [...products,...res.products],
					// hasMore: res.total > (PRODUCTS_PER_PAGE * (page+1)),
					hasMoreExternal: res.totalExternal > (PRODUCTS_PER_PAGE * (page+1)),
					hasMoreInternal: res.totalInternal > (PRODUCTS_PER_PAGE * (page+1)),
					page: page + 1
				},
			});
		}
	} catch (error) {
		dispatch({
			type: PRODUCTS_ACTION_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_PRODUCTS_OVERLAY,
		});
	}
}

export const uploadInternalProductAction = (id, data) => async (dispatch, getState) => {
	dispatch({
		type: START_UPDATE_PRODUCT
	});
	try {
		const formData = new FormData();

		formData.append('image', data.image);
		formData.append('name', data.name);
		formData.append('description', data.description);
		formData.append('price', data.price);
		formData.append('supplier', data.supplier);
		formData.append('categorieId', data.categorieId);

		const res = await httpApiPatch(`internal-products/update/${id}`, formData);
		if (res) {
			res.data.image = res.data.image + `?${moment().unix()}`;

			dispatch({
				type: GET_PRODUCTS_SUCCESS,
				payload: {
					products: getState().product.products.map(product => product.id === id ? res.data : product),
				},
			});
		}
	} catch (error) {
		dispatch({
			type: PRODUCTS_ACTION_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_UPDATE_PRODUCT
		});
	}
}



export const resetProductsErrorMessage = () => async (dispatch, getState) => {
	dispatch({
		type: RESET_PRODUCTS_MESSAGE,
	});
}