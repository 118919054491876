import { httpApiGet, httpApiPost, httpApiDelete, httpApiUploadFile, httpApiPatch } from "../helpers/axiosPrivate";
import { sortArrayByLabel } from "../helpers/sortArrayByLabel";

const initialState = {
	appDataLoaded: false,
	roles: [],
	internalCategoriesSelect:[],
	internalCategories: [],
	clients: [],
	users: [],
	paymentConditions: [],
	orderNotes: [],
	suppliers: [],
	printingTechniques: [],
	numberColors: [],
	colors: [],
	reloadPagination: false,
	loading: false,
	loadingOverlay: false,
	error: false,
	message: '',
	isSubmitting: false,
}

const GET_APP_DATA_SUCCESS = 'GET_APP_DATA_SUCCESS'
const GET_SELECT_DATA_SUCCESS = 'GET_SELECT_DATA_SUCCESS'
const APP_DATA_FAILURE = 'APP_DATA_FAILURE'
const START_LOADING_APP_DATA = 'START_LOADING_APP_DATA'
const STOP_LOADING_APP_DATA = 'STOP_LOADING_APP_DATA'
const START_LOADING_APP_DATA_OVERLAY = 'START_LOADING_APP_DATA_OVERLAY'
const STOP_LOADING_APP_DATA_OVERLAY = 'STOP_LOADING_APP_DATA_OVERLAY'
const RESET_APP_DATA_MESSAGE = 'RESET_APP_DATA_MESSAGE'
const SHOW_APP_DATA_MESSAGE = 'SHOW_APP_DATA_MESSAGE'
const GET_CLIENTS_APP_DATA_SUCCESS = 'GET_CLIENTS_APP_DATA_SUCCESS'
const START_RELOAD_PAGINATION = 'START_RELOAD_PAGINATION'
const STOP_RELOAD_PAGINATION = 'STOP_RELOAD_PAGINATION'
const SET_IS_SUBMITTING = 'SET_IS_SUBMITTING'
const RESET_CLIENTS = 'RESET_CLIENTS'
const GET_COLORS_APP_DATA_SUCCESS = 'GET_COLORS_APP_DATA_SUCCESS'

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_APP_DATA_SUCCESS:
			return {
				...state,
				appDataLoaded: true,
				...action.payload
			}
		case GET_SELECT_DATA_SUCCESS:
			return {
				...state,
				...action.payload
			}
		case APP_DATA_FAILURE:
			return {
				...state,
				message: action.message,
				error: true
			}
		case START_LOADING_APP_DATA:
			return {
				...state,
				loading: true,
			}
		case STOP_LOADING_APP_DATA:
			return {
				...state,
				loading: false,
			}
		case START_LOADING_APP_DATA_OVERLAY:
			return {
				...state,
				loadingOverlay: true,
			}
		case STOP_LOADING_APP_DATA_OVERLAY:
			return {
				...state,
				loadingOverlay: false,
			}
		case SHOW_APP_DATA_MESSAGE:
			return {
				...state,
				message: action.message,
				error: action.payload
			}
		case RESET_APP_DATA_MESSAGE:
			return {
				...state,
				message: '',
				error: false
			}
		case GET_CLIENTS_APP_DATA_SUCCESS:
			return {
				...state,
				message: action.payload.message,
				clients: [...state.clients, ...action.payload.clients]
			}
		case START_RELOAD_PAGINATION:
			return {
				...state,
				reloadPagination: true
			}
		case STOP_RELOAD_PAGINATION:
			return {
				...state,
				reloadPagination: false
			}

		case SET_IS_SUBMITTING:
			return {
				...state,
				isSubmitting: action.payload
			}

		case RESET_CLIENTS:
			return {
				...state,
				clients: action.payload
			}
		case GET_COLORS_APP_DATA_SUCCESS:
			return {
				...state,
				colors: action.payload
			}

		default:
			return state;
	}
}

export const getAppDataAction = () => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA,
	});
	Promise.all([
		httpApiGet('internal-categories'),
		httpApiGet('clients'),
	]).then(values => {
		const data = {
			internalCategories: values[0],
			clients: values[1],
		}
		if (data) {
			dispatch({
				type: GET_APP_DATA_SUCCESS,
				payload: data,
			});
		}
	}).catch(reason => {
		dispatch({
			type: APP_DATA_FAILURE,
			message: 'Hubo un error cargando los datos de la aplicación',
		});
	});
	dispatch({
		type: STOP_LOADING_APP_DATA,
	});
}

export const getSelectDataAction = () => async (dispatch, getState) => {

	Promise.all([
		httpApiGet('app-data/payment-conditions'),
		httpApiGet('app-data/order-notes'),
		httpApiGet('app-data/printing-techniques'),
		httpApiGet('app-data/number-colors'),
		httpApiGet('suppliers'),
		httpApiGet('users/all/')
	]).then(values => {
		const data = {
			paymentConditions: values[0],
			orderNotes: values[1],
			printingTechniques: values[2],
			numberColors: values[3],
			suppliers: values[4],
			users: values[5]
		}
		if (data) {
			dispatch({
				type: GET_SELECT_DATA_SUCCESS,
				payload: data,
			});
		}
	}).catch(reason => {
		dispatch({
			type: APP_DATA_FAILURE,
			message: 'Hubo un error cargando los datos de la aplicación',
		});
	});

}

export const getCategoriesAction = (withLoading = false) => async (dispatch, getState) => {
	if (withLoading) {
		dispatch({
			type: START_LOADING_APP_DATA_OVERLAY,
		});
	}
	try {
		const data = await httpApiGet('internal-categories');
		if (data) {

			let internalCategoriesSelect = data.map( it => ({ label: it.name, value: it.id }) )
			internalCategoriesSelect = sortArrayByLabel(internalCategoriesSelect)

			dispatch({
				type: GET_APP_DATA_SUCCESS,
				payload: {
					internalCategories: data,
					internalCategoriesSelect
				},
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		if (withLoading) {
			dispatch({
				type: STOP_LOADING_APP_DATA_OVERLAY,
			});
		}
	}
}

export const saveCategoriesAction = (internalCategoriesEdit) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiPost('internal-categories', internalCategoriesEdit);
		if (data) {
			dispatch({
				type: GET_APP_DATA_SUCCESS,
				payload: { internalCategories: data },
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
	}
}

// clientes
export const getClientsAction = () => async (dispatch, getState) => {
	httpApiGet('clients')
		.then(values => {
			if (values) {
			const data = {
				clients: values
			}
				dispatch({
					type: GET_APP_DATA_SUCCESS,
					payload: data,
				});
			}
		}).catch(reason => {
			dispatch({
				type: APP_DATA_FAILURE,
				message: 'Hubo un error cargando los datos de los clientes',
			});
		});
}

export const saveOneClientAction = (client) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiPost('clients/add', {name: client});
		if(data){
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: { 
					clients: data,
					message: 'Cliente agregado correctamente'
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}


export const loadClientsAction = (clientsFile) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiUploadFile('clients/load', clientsFile);
		if (data) {
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: { 
					clients: data.data,
					message: data.message
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const deleteClientAction = (clientId) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiDelete(`clients/${clientId}`);
		if (data) {
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: {
					clients: data,
					message: 'Cliente eliminado correctamente'
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const updateClientAction = (clientId, client) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiPatch(`clients/${clientId}`, { name: client });
		if (data) {
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: {
					clients: data,
					message: 'Cliente actualizado correctamente'
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const resetAppDataMessage = () => async (dispatch, getState) => {
	dispatch({
		type: RESET_APP_DATA_MESSAGE,
	});
}

export const resetClients = () => async (dispatch, getState) => {
	const clients = await httpApiGet('clients')
	dispatch({
		type: RESET_CLIENTS,
		payload: clients
	});
}

export const loadAppDataColors = () => async (dispatch, getState) => {
	httpApiGet('colors')
		.then(values => {
			if (values) {
				dispatch({
					type: GET_COLORS_APP_DATA_SUCCESS,
					payload: values,
				});
			}
		}).catch(reason => {
			dispatch({
				type: APP_DATA_FAILURE,
				message: 'Hubo un error cargando los datos de los colores',
			});
		});
}





